import $api from "../http";

export default class UserService {
    static async getAllUsers() {
        return await $api.get("users");
    }

    static async getUser(id) {
        return await $api.post("user", id);
    }

    static async saveUserData(data) {
        return await $api.post("save-user", data);
    }

    static async deleteUser(userID) {
        return await $api.post("delete-user", userID);
    }
}