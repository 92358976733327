import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Fragment, useCallback, useEffect, useState} from "react";
import {addUser, IsLoading} from "../../redux/slices/userSlice";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import AuthService from "../../services/AuthService";
import {FormControl, FormHelperText, InputAdornment, InputLabel, OutlinedInput} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";

export default function LoginModal() {
    const [isPassShow, setIsPassShow] = useState(false);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const handleClickOpen = () => {
        setOpen(true);
    };
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false)
    const navigate = useNavigate()
    const [error, setError] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);

    const login = useCallback(async () => {
        dispatch(IsLoading(true));
        try {
            const response = await AuthService.login(email, password);
            const session = response.data.session;
            const { userData } = response.data;
            const id = response.data.userData.user[0].id;
            localStorage.setItem('session', session);
            localStorage.setItem('user', id);
            dispatch(addUser(userData.user[0]));
            navigate('/users');
        } catch (error) {
            setError(error.response?.data?.message);
        } finally {
            dispatch(IsLoading(false));
        }
    }, [email, password, rememberMe, dispatch, navigate]);

    useEffect(() => {
        handleClickOpen()
    }, []);
    useEffect(() => {
        const handleEnterPress = (event) => {
            if (event.key === 'Enter') {
                login();
            }
        };

        document.addEventListener('keydown', handleEnterPress);
        return () => {
            document.removeEventListener('keydown', handleEnterPress);
        };
    }, [login]);
    useEffect(() => {
        const borderColor = (selector, color) => {
            document.querySelector(selector).style.borderColor = color;
        };
        switch (error) {
            case'User with email not found':
                setIsPasswordValid(true);
                setIsEmailValid(false);
                break
            case'Incorrect password':
                setIsPasswordValid(false);
                setIsEmailValid(true);
                break
            case'User with email not found,Incorrect password':
                setIsPasswordValid(true);
                setIsEmailValid(true);
                break
            default:
                setIsPasswordValid(false);
                setIsEmailValid(false);
                break
        };
    }, [error]);
    const passShow = useCallback(() => {
        setIsPassShow((prev) => !prev);
    }, []);
    return (
        <Fragment>
            <Dialog
                open={open}
                PaperProps={{
                    component: 'div',
                    sx:{backgroundColor: "#0D1018", color: "#fff"},
                }}
            >
                <DialogTitle>Login</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="name"
                        name="email"
                        label="Email"
                        type="email"
                        fullWidth
                        variant="outlined"
                        sx={{color: "#fff", marginBottom: "15px"}}
                        onChange={(e => setEmail(e.target.value))}
                    />
                    {isEmailValid && <FormHelperText error id="outlined-weight-helper-text">{"User with email not found"}</FormHelperText>}
                    <FormControl sx={{ width: '100%' }} variant="outlined">
                        <InputLabel error={isPasswordValid && "error"} htmlFor="outlined-adornment-password">{"Password"}</InputLabel>
                        <OutlinedInput
                            error={isPasswordValid && "error"}
                            id="outlined-adornment-password"
                            type={isPassShow ? 'text' : 'password'}
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            sx={{color: "#fff"}}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={passShow}
                                        edge="end"
                                        sx={{color: "#fff"}}
                                    >
                                        {isPassShow ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label={"Password"}
                        />
                        {isPasswordValid && <FormHelperText error={isPasswordValid && "error"} id="outlined-weight-helper-text">{"Incorrect password"}</FormHelperText>}
                    </FormControl>
                    {isEmailValid && <FormHelperText error id="outlined-weight-helper-text">{"User with email not found"}</FormHelperText>}
                </DialogContent>
                <DialogActions sx={{padding: '0 20px 24px'}}>
                    <Button fullWidth onClick={login} variant="contained">Login</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}