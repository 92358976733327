import './App.css';
import MenuComponent from "./components/menu/MenuComponent"
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import Users from "./pages/Users";
import {LinearProgress} from "@mui/material";
import * as React from "react";
import Analytic from "./pages/Analytic";
import AuthorizationPage from "./pages/AuthorizationPage";

function App() {
    const location = useLocation();

    return (
        <div className="App">
            <div className="d-flex align-items-start">
                {location.pathname !== "/" && <MenuComponent/>}
                <Routes>
                    <Route path="/" element={<AuthorizationPage/>}/>
                    <Route path="/users" element={<Users/>}/>
                    <Route path="/analytic" element={<Analytic/>}/>
                </Routes>
            </div>
        </div>
    );
}

export default App;
