import React from 'react';
import LoginModal from "../components/authorization/LoginModal";

function AuthorizationPage(props) {
    return (
        <section>
            <LoginModal/>
        </section>
    );
}

export default AuthorizationPage;